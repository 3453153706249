<template>
  <div>
    <b-row class="px-3 py-4">
      <b-col lg="3" xs="12" sm="6">
        <div class="d-flex align-items-center">
          <div class="mr-3 position-relative" style="height: 80px">
            <i
              class="mdi mdi-home text-primary display-7 chart-inner-icon"
            ></i>
            <vue-apex-charts
              type="donut"
              height="90"
              width="90"
              :options="RealTimeVisitsData.searchChart.chartOptions"
              :series="[unitPendingCnt, 100 - unitPendingCnt]"
            ></vue-apex-charts>
          </div>
          <div>
            <h3 class="mb-0">{{unitPendingCnt}}건</h3>
            <span>신규 세대인증 요청 건수</span>
          </div>
        </div>
      </b-col>
      <b-col lg="3" xs="12" sm="6" class="mt-3 mt-md-0">
        <div class="d-flex align-items-center">
          <div class="mr-3 position-relative" style="height: 80px">
            <i class="mdi mdi-lightbulb-outline text-danger display-7 chart-inner-icon"></i>
            <vue-apex-charts
              type="donut"
              height="90"
              width="90"
              :options="RealTimeVisitsData.referralChart.chartOptions"
              :series="RealTimeVisitsData.referralChart.series"
            ></vue-apex-charts>
          </div>
          <div>
            <h3 class="mb-0">15건</h3>
            <span>민원 요청 건수</span>
          </div>
        </div>
      </b-col>
      <b-col lg="3" xs="12" sm="6" class="mt-3 mt-md-0">
        <div class="d-flex align-items-center">
          <div class="mr-3 position-relative" style="height: 80px">
            <i
              class="mdi mdi-calendar-today text-info display-7 chart-inner-icon"
            ></i>
            <vue-apex-charts
              type="donut"
              height="90"
              width="90"
              :options="RealTimeVisitsData.socialChart.chartOptions"
              :series="RealTimeVisitsData.socialChart.series"
            ></vue-apex-charts>
          </div>
          <div>
            <h3 class="mb-0">17건</h3>
            <span>신규 시설예약 요청 건수</span>
          </div>
        </div>
      </b-col>
      <b-col lg="3" xs="12" sm="6" class="mt-3 mt-md-0">
        <div class="d-flex align-items-center">
          <div class="mr-3 position-relative" style="height: 80px">
            <i
              class="mdi mdi-car text-success display-7 chart-inner-icon"
            ></i>
            <vue-apex-charts
              type="donut"
              height="90"
              width="90"
              :options="RealTimeVisitsData.directChart.chartOptions"
              :series="RealTimeVisitsData.directChart.series"
            ></vue-apex-charts>
          </div>
          <div>
            <h3 class="mb-0">23건</h3>
            <span>미확인 방문차량 등록 건수</span>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import RealTimeVisitsData from "./RealTimeVisitsData";

export default {
  name: "RealTimeVisits",
  props: {
    unitPendingCnt: Number
  },
  data: () => ({
    title: "RealTimeVisits",
    RealTimeVisitsData: RealTimeVisitsData,
  }),
  components: {
    VueApexCharts,
  },
};
</script>