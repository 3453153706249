<style>
  .main-bg {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 150px;
    border-radius: 5px;
  }

  .main-bg img{
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    object-fit: cover;
  }

  .main-bg div{
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    width: 100%;
    height: 100%;
    color:white;
    font-size: 18px;
    padding: 10px 20px;
    font-weight: bold;
  }

  .main-bg div strong{ 
    font-size: 20px;
    display: block;
    margin-bottom: 10px;
  }
</style>

<template>
  <div>
    <figure class="main-bg">
      <img src="@/assets/images/background/home_background.jpg" alt="image" />
      <div>
        <strong> {{ weather.T1H }}° ({{weather.PTYHAN}}) </strong>
        <p>
          최고: {{ weather.TMX }}°<br/>
          최저: {{ weather.TMN }}°
        </p>
      </div>
    </figure>

    <b-card no-body class="mb-4">
      <!-- Header -->
      <div class="d-md-flex align-items-center card-body">
        <div>
          <h4 class="card-title">{{ $store.state.AptName }}</h4>
        </div>
      </div>
      <RealTimeVisits :unitPendingCnt="unitPendingCnt"></RealTimeVisits>
    </b-card>
    <BaseCard title="HooH IoT 이용 관련 문의 정보" modalid="my-modal">
      <template v-slot:comcode>
        상호명 : 후아이오티<br/>
        사업자등록번호: 196-03-02635<br/>
        대표자명: 최창하<br/>
        고객센터 번호(대표번호) : 010-2786-3623
      </template>
    </BaseCard>
  </div>  
</template>

<script>
import { dashboard } from '../api';
import BaseCard from '../components/card/BaseCard'
import RealTimeVisits from '../components/widgets/RealTimeVisits.vue';
export default {
  name: "Starter",
  components:{
    BaseCard,
    RealTimeVisits
  },
  methods: {
    async getDashboard(){
      let dashboardRes = await dashboard();
      if(dashboardRes.error == true)
      {
        if(dashboardRes.loginRequired){
          this.$router.replace("/login");
        }
        return;
      }
      this.unitPendingCnt = dashboardRes.unitPendingCnt;
      this.weather = dashboardRes.weather;
      if(this.weather.PTY == 0)
        this.weather.PTYHAN = "맑음";
      if(this.weather.PTY == 1)
        this.weather.PTYHAN = "비";
      if(this.weather.PTY == 2)
        this.weather.PTYHAN = "비/눈";
      if(this.weather.PTY == 3)
        this.weather.PTYHAN = "눈";
      if(this.weather.PTY == 5)
        this.weather.PTYHAN = "빗방울";
      if(this.weather.PTY == 6)
        this.weather.PTYHAN = "빗방울눈날림";
      if(this.weather.PTY == 7)
        this.weather.PTYHAN = "눈날림";

      this.isLoaded = true;
    }
  },
  async created() {
    await this.getDashboard();
  },
  data: () => ({
    weather: {
      TMX: 0.0,
      TMN: 0.0,
      T1H: 0.0,
      PTY: 0,
      PTYHAN: "맑음"
    },
    isLoaded: false,
    unitPendingCnt: 0
  })
};

</script>
