<template>
  <b-card class="w-100 mb-4" no-body>
    <div class="p-35">
      <div class="d-flex align-items-start">
        <div>
          <h4 class="card-title mb-1">{{ title }}</h4>
        </div>
       
      </div>
    </div>
    <b-card-body>
      <slot name="comcode"></slot>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "BaseCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    modalid: {
      type: String,
      default: "",
    },
    modaltitle: {
      type: String,
      default: "",
    },
  },
};
</script>

